<template>
  <div>
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Results', route: 'Results' },
        { name: 'Result Details', route: 'Results' },
      ]"
    />

    <div class="placeholder">
      <div class="row">
        <div class="col-6">
          <div class="form">
            <label for="TestDetails.cow_id">Sample ID</label>
            <input
              type="text"
              class="input"
              v-model="TestDetails.cow_id"
              readonly
            />
            <div
              v-if="TestDetails.farmer_name !== '' && TestDetails.farmer_name"
            >
              <label for="TestDetails.farmer_name">Client Name</label>
              <input
                type="text"
                class="input"
                v-model="TestDetails.farmer_name"
                readonly
              />
            </div>
            <label for="TestDetails.animaltype">Animal Type</label>
            <select
              name="TestDetails.animaltype"
              v-model="TestDetails.animaltype"
              id="TestDetails.animaltype"
              class="input"
              readonly
            >
              <option v-if="TestDetails.animaltype == 1" value="1">
                Bovine
              </option>
              <option v-if="TestDetails.animaltype == 2" value="2">
                Ovine
              </option>
              <option v-if="TestDetails.animaltype == 3" value="3">
                Equine
              </option>
            </select>
            <label for="TestDetails.sampletype">Sample Type</label>
            <select
              name="TestDetails.sampletype"
              v-model="TestDetails.sampletype"
              id="TestDetails.sampletype"
              class="input"
              readonly
            >
              <option v-if="TestDetails.sampletype == 1" value="1">
                Individual
              </option>
              <option v-if="TestDetails.sampletype == 2" value="2">
                Pooled
              </option>
            </select>
            <div v-if="TestDetails.notes !== '' && TestDetails.notes">
              <label for="TestDetails.notes">Notes</label>
              <textarea
                type="text"
                class="input"
                rows="3"
                v-model="TestDetails.notes"
                readonly
              ></textarea>
            </div>
            <label>{{ TimeParse(TestDetails.created_at) }}</label>
            <div v-if="TestDetails.test_type==3">
            <button
              class="btn btn-green"
              v-if="
                TestDetails.status !== 'process' &&
                TestDetails.status !== 'failed'
              "
              style="background-color: #A399B8; color: white;"
              v-on:click="downloadFEC"
            >
              Download Results
            </button>
          </div>
          <div v-if="(TestDetails.test_type!=3)">
            <button
              class="btn btn-green"
              v-if="
                TestDetails.status !== 'process' &&
                TestDetails.status !== 'failed'
              "
              v-on:click="downloadFEC"
            >
              Download Results
            </button>

          </div>
            
          </div>
        </div>
        <div class="col-6">
          <div class="testindex-placeholder">
            <div class="index">
              <span
                v-if="TestDetails.status == 'green'"
                class="icon icon-heart"
                style="color: #04a394"
              ></span>
              <span
                v-else-if="TestDetails.status == 'red'"
                class="icon icon-warning"
                style="color: #d50014"
              ></span>
              <span
                v-else-if="TestDetails.status == 'failed'"
                class="icon icon-warning"
                style="color: grey"
              ></span>
              <span v-else class="icon icon-heart"></span>
            </div>
            <div v-if = "TestDetails.test_type !=3 " class="title">{{ TestDetails.uuid !== null ? TestDetails.uuid : this.$route.params.index}}</div>
            <!-- <div v-if = "TestDetails.test_type !=3 " class="title">#{{ getuniqueID(TestDetails.test_type,TestDetails.id,TestDetails.businesscountry) }}</div> -->
            <div v-if = "TestDetails.test_type ==3 " class="title_fluke">{{ TestDetails.uuid !== null ? TestDetails.uuid : this.$route.params.index}}</div>
          </div>
          <div
            v-if="
              TestDetails.status !== 'process' &&
              TestDetails.status !== 'failed'
            "
            class="Results"
          >
          <div v-if="TestDetails.test_type!=3">
            <div v-if="(TestDetails.animaltype == 1 || TestDetails.animaltype == 2 ||TestDetails.animaltype == 4)">
              <div
                class="result-placeholder"
                v-for="row in Results"
                :key="row.name"
              >
                <div class="pest">{{ row.name }}</div>
                <div class="status" v-if="row.count > 0" style="color: red">
                  {{
                    eggBurdenLevel(row.name, TestDetails.animaltype,row.count)
                  }}
                </div>
                <div class="status" v-else style="color: green">
                  {{
                    eggBurdenLevel(row.name, TestDetails.animaltype, row.count)
                  }}
                </div>
              </div>
            </div>
            <div v-if="TestDetails.animaltype == 3">
              <div
                class="result-placeholder"
                v-for="row in Results_equine"
                :key="row.name"
                >
                <div class="pest">{{ row.name }}</div>
                <div class="status" v-if="row.count > 0" style="color: red">
                  {{
                    eggBurdenLevel(row.name, TestDetails.animaltype, row.count)
                  }}
                </div>
                <div class="status" v-else style="color: green">
                  {{
                    eggBurdenLevel(row.name, TestDetails.animaltype, row.count)
                  }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="TestDetails.test_type==3">
            <div
                class="result-placeholder"
                v-for="row in Results_fluke"
                :key="row.name"
                >
                <div class="pest">{{ row.name }}</div>
                <div class="status" v-if="row.count > 0" style="color: red">
                  {{
                    eggBurdenLevel(row.name, TestDetails.animaltype, row.count)
                  }}
                </div>
                <div class="status" v-else style="color: green">
                  {{
                    eggBurdenLevel(row.name, TestDetails.animaltype, row.count)
                  }}
                </div>
              </div>
          </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";

import Navigation from "@/components/Navigation.vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();

    return { store };
  },
  name: "Result_View",
  data() {
    return {
      TestDetails: {},
      Results: [
        { name: "Coccidia", count: 0 },
        { name: "Strongyle", count: 0 },
        { name: "Nematodirus", count: 0 },
        { name: "Trichuris", count: 0 },
        { name: "Strongyloides", count: 0 },
        { name: "Moniezia", count: 0 },
      ],
      Results_equine: [
        { name: "Coccidia", count: 0 },
        { name: "Strongyle", count: 0 },
        { name: "Strongyloides", count: 0 },
        { name: "Parascaris", count: 0 },
        { name: "Anoplocephala", count: 0 },
   
      ],
      Results_fluke: [
        { name: "Liver Fluke", count: 0 },
        { name: "Rumen Fluke", count: 0 },

   
      ],
    };
  },
  components: {
    Navigation,
  },
  methods: {

    GetTest() {
      this.store
        .dispatch("Tests/GetInfo", { id: this.$route.params.id })
        .then((result) => {
          this.TestDetails = result.data;
          console.log("Here is the details..$$$$$$$$.......",this.TestDetails);
          result.result.forEach((row) => {
            this.setResult(row.pest, row.pest_count);
          });
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    TimeParse(time) {
      let date = new Date(time);
      let returntime = `${("0" + date.getDate()).slice(-2)}/${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}/${date.getFullYear()} ${("0" + date.getHours()).slice(
        -2
      )}:${("0" + date.getMinutes()).slice(-2)}`;

      return returntime;
    },
    downloadFEC() {
      this.store
        .dispatch("Tests/DownloadFEC", { id: this.$route.params.id })
        .then((result) => {
          // Begin PDF
          html2pdf(result.html, {
            margin: [0, 8, 15, 0],
            filename: this.TestDetails.cow_id + " - FEC.pdf",
            type: "jpeg",
            quality: 100,
            html2canvas: { dpi: 900, letterRendering: true, scale: 3 },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          });
        })
        .catch((err) => {
          console.log(err.messages);
        });
    },
    eggBurdenLevel(egg, animal, count) {
      console.log(egg,count);
      if (count === -1) {
        return "Invalid";
      }
      if (animal == 1) {
        //Cattle
        // Light (30 - 1000)
        // Moderate (1000 - 2000)
        // High (2000 - 4000)
        // Very High (4000+)
        if (egg.toLowerCase() == "coccidia") {
          if (count >= 30 && count <= 1000) {
            return "Low";
          } else if (count > 1000 && count <= 2000) {
            return "Moderate";
          } else if (count > 2000 && count <= 4000) {
            return "High";
          } else if (count > 4000) {
            return "Very High";
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "nematodirus") {
          if (count >= 30 && count <= 200) {
            return `${count} EPG`;
          } else if (count > 200 && count <= 500) {
            return `${count} EPG`;
          } else if (count >= 500) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "strongyle") {
          if (count >= 30 && count <= 200) {
            return `${count} EPG`;
          } else if (count > 200 && count <= 600) {
            return `${count} EPG`;
          } else if (count >= 600) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "trichuris") {
          if (count >= 30 && count <= 150) {
            return `${count} EPG`;
          } else if (count > 150 && count <= 500) {
            return `${count} EPG`;
          } else if (count >= 500) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "strongyloides") {
          if (count >= 30 && count <= 250) {
            return `${count} EPG`;
          } else if (count > 250 && count <= 1250) {
            return `${count} EPG`;
          } else if (count >= 1250) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "moniezia") {
          if (count >= 30 && count <= 500) {
            return `${count} EPG`;
          } else if (count > 500 && count <= 2500) {
            return `${count} EPG`;
          } else if (count >= 2500) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        }
        else if(egg.toLowerCase() == "liver fluke"){
          if (count>=1)
          {
            return "Positive";
          }
          else{
            return "No Eggs Detected";
          }

        }
        else if(egg.toLowerCase() == "rumen fluke"){
          if (count>=1)
          {
            return "Positive";
          }
          else{
            return "No Eggs Detected";
          }

        }
        
        else {
          if (count >= 30) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        }
      } else if (animal == 2) {
        //Sheep
        // Low (30 - 1000)
        // Moderate (1000 - 2000)
        // High (2000 - 4000)
        // Very High (4000+)
        if (egg.toLowerCase() == "coccidia") {
          if (count >= 30 && count <= 1000) {
            return "Low";
          } else if (count > 1000 && count <= 2000) {
            return "Moderate";
          } else if (count > 2000 && count <= 4000) {
            return "High";
          } else if (count > 4000) {
            return "Very High";
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "nematodirus") {
          if (count >= 30 && count <= 200) {
            return `${count} EPG`;
          } else if (count > 200 && count <= 500) {
            return `${count} EPG`;
          } else if (count >= 500) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "strongyle") {
          if (count >= 30 && count <= 500) {
            return `${count} EPG`;
          } else if (count > 500 && count <= 2000) {
            return `${count} EPG`;
          } else if (count >= 2000) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "trichuris") {
          if (count >= 30 && count <= 150) {
            return `${count} EPG`;
          } else if (count > 150 && count <= 500) {
            return `${count} EPG`;
          } else if (count >= 500) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "strongyloides") {
          if (count >= 30 && count <= 250) {
            return `${count} EPG`;
          } else if (count > 250 && count <= 1250) {
            return `${count} EPG`;
          } else if (count >= 1250) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "moniezia") {
          if (count >= 30 && count <= 500) {
            return `${count} EPG`;
          } else if (count > 500 && count <= 2500) {
            return `${count} EPG`;
          } else if (count >= 2500) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        } 
        else if(egg.toLowerCase() == "liver fluke"){
          if (count>=1)
          {
            return "Positive";
          }
          else{
            return "No Eggs Detected";
          }

        }
        else if(egg.toLowerCase() == "rumen fluke"){
          if (count>=1)
          {
            return "Positive";
          }
          else{
            return "No Eggs Detected";
          }

        }
        
        
        else {
          if (count >= 30) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        }
      } else if (animal == 3) {
        //Equine
        // Low (30 - 1000)
        // Moderate (1000 - 2000)
        // High (2000 - 4000)
        // Very High (4000+)
        if (egg.toLowerCase() == "coccidia") {
          if (count >= 30 && count <= 1000) {
            return "Low";
          } else if (count > 1000 && count <= 2000) {
            return "Moderate";
          } else if (count > 2000 && count <= 4000) {
            return "High";
          } else if (count > 4000) {
            return "Very High";
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "strongyle") {
          if (count >= 30 && count <= 500) {
            return `${count} EPG`;
          } else if (count > 500 && count <= 2000) {
            return `${count} EPG`;
          } else if (count >= 2000) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "strongyloides") {
          if (count >= 30 && count <= 150) {
            return `${count} EPG`;
          } else if (count > 150 && count <= 500) {
            return `${count} EPG`;
          } else if (count >= 500) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "parascaris") {
          if (count >= 30 && count <= 250) {
            return `${count} EPG`;
          } else if (count > 250 && count <= 1250) {
            return `${count} EPG`;
          } else if (count >= 1250) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "anoplocephala") {
          if (count >= 30 && count <= 500) {
            return `${count} EPG`;
          } else if (count > 500 && count <= 2500) {
            return `${count} EPG`;
          } else if (count >= 2500) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        }
        else if(egg.toLowerCase() == "liver fluke"){
          if (count>=1)
          {
            return "Positive";
          }
          else{
            return "No Eggs Detected";
          }

        }
        else if(egg.toLowerCase() == "rumen fluke"){
          if (count>=1)
          {
            return "Positive";
          }
          else{
            return "No Eggs Detected";
          }

        }
               
        else {
          if (count >= 30) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        }
      } else if (animal == 4) {
        //Cattle
        // Light (30 - 1000)
        // Moderate (1000 - 2000)
        // High (2000 - 4000)
        // Very High (4000+)
        if (egg.toLowerCase() == "coccidia") {
          if (count >= 30 && count <= 1000) {
            return "Low";
          } else if (count > 1000 && count <= 2000) {
            return "Moderate";
          } else if (count > 2000 && count <= 4000) {
            return "High";
          } else if (count > 4000) {
            return "Very High";
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "nematodirus") {
          if (count >= 30 && count <= 200) {
            return `${count} EPG`;
          } else if (count > 200 && count <= 500) {
            return `${count} EPG`;
          } else if (count >= 500) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "strongyle") {
          if (count >= 30 && count <= 200) {
            return `${count} EPG`;
          } else if (count > 200 && count <= 600) {
            return `${count} EPG`;
          } else if (count >= 600) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "trichuris") {
          if (count >= 30 && count <= 150) {
            return `${count} EPG`;
          } else if (count > 150 && count <= 500) {
            return `${count} EPG`;
          } else if (count >= 500) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "strongyloides") {
          if (count >= 30 && count <= 250) {
            return `${count} EPG`;
          } else if (count > 250 && count <= 1250) {
            return `${count} EPG`;
          } else if (count >= 1250) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        } else if (egg.toLowerCase() == "moniezia") {
          if (count >= 30 && count <= 500) {
            return `${count} EPG`;
          } else if (count > 500 && count <= 2500) {
            return `${count} EPG`;
          } else if (count >= 2500) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        }
        else if(egg.toLowerCase() == "Liver Fluke"){
          if (count>=1)
          {
            return "Positive";
          }
          else{
            return "No Eggs Detected";
          }

        }
        else if(egg.toLowerCase() == "liver fluke"){
          if (count>=1)
          {
            return "Positive";
          }
          else{
            return "No Eggs Detected";
          }

        }
        else if(egg.toLowerCase() == "rumen fluke"){
          if (count>=1)
          {
            return "Positive";
          }
          else{
            return "No Eggs Detected";
          }

        }
        
        else {
          if (count >= 30) {
            return `${count} EPG`;
          } else {
            return "Not Detected";
          }
        }
      }
    },
    setResult(pest, count) {
     
      if((this.TestDetails.animaltype!=3)&&(this.TestDetails.test_type!=3)){
      this.Results = this.Results.map((result) =>
        result.name.toLowerCase() === pest.toLowerCase()
          ? { ...result, count }
          : result
      );
      }
      if((this.TestDetails.animaltype==3)&&(this.TestDetails.test_type!=3)){
        this.Results_equine = this.Results_equine.map((result) =>
        result.name.toLowerCase() === pest.toLowerCase()
          ? { ...result, count }
          : result
      );
      }
      if(this.TestDetails.test_type==3){

        this.Results_fluke = this.Results_fluke.map((result) =>
        result.name.toLowerCase() === pest.toLowerCase()
          ? { ...result, count }
          : result
      );


      }

    },
    convertBussidToBussId(bussid) {
    if (bussid >= 0 && bussid < 1000) {
    const prefixes = ['A', 'B', 'C', 'D', 'E','F','G','H','I','J','K'];
    let prefixIndex = Math.floor(bussid / 100);
    if (prefixIndex >= 0 && prefixIndex < prefixes.length) {
      if (bussid === prefixIndex * 100) {
        return prefixes[prefixIndex] + '00';
      } else {
        let numberPart = (bussid % 100).toString().padStart(2, '0');
        return prefixes[prefixIndex] + numberPart;
      }
    }
  }
  return 'Invalid bussid';
},
  converttestidtoUUID(id){
      let combinationCount = 0;
      
      if (id > 104000){
        id = id % 104000;
      }
      const targetCombinationNumber = id;
      for (let num1 = 0; num1 <= 9; num1++) {
        for (let num2 = 0; num2 <= 9; num2++) {
          for (let num3 = 0; num3 <= 9; num3++) {
            for (let letter = 'A'.charCodeAt(0); letter <= 'Z'.charCodeAt(0); letter++) {
              for (let position = 1; position <= 4; position++) {
                let combination = '';
                for (let i = 1; i <= 4; i++) {
                  if (i === position) {
                    combination += String.fromCharCode(letter);
                  } else {
                    combination += String(i <= 3 ? num1 : num2);
                  }
                }
                combinationCount++;

                if (combinationCount === targetCombinationNumber) {
                  console.log(`The combination is: ${combination}`);
                  return combination;
                  // Break out of all loops
                  // num1 = 10; num2 = 10; num3 = 10; letter = 'Z'.charCodeAt(0); position = 5;
                }
              }
            }
          }
        }
      }

  }
,
    getuniqueID(testtype,bussid,id){
     
      let type = "W"
      if(testtype==3){
        type = "F";
      }
      let buss_id = this.convertBussidToBussId(bussid);
      let uuid = this.converttestidtoUUID(id);
      let Year = new Date().getFullYear();

   
      
      return type + `${buss_id}` +'-'+ `${uuid}` +'-'+ `${Year.toString()}`


    }
  },
  mounted() {
    this.GetTest();
  },
};
</script>

<style>
.testindex-placeholder {
  align-content: center;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.index {
  position: absolute;
  background: #ffffff;
  color: #4d4d4d;
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px;
  border: 2px solid #e4e7ee;
  border-radius: 100%;
  font-weight: bolder;
  font-size: 70px;
  display: block;
  z-index: 2;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  box-sizing: border-box;
}

.title {
  background: rgba(53, 187, 164, 19%);
  color: #35bba4;
  font-family: Arial, Helvetica, sans-serif;
  padding: 20px;
  font-weight: bold;
  border-radius: 10px;
  font-size: 40px;
  display: block;
  margin-top: 85px;
  line-height: 67px;
  height: 40px;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  text-align: center;
}
.title_fluke {
  background: rgba(204, 185, 205, 19%);
  color: #A399B8;
  font-family: Arial, Helvetica, sans-serif;
  padding: 20px;
  font-weight: bold;
  border-radius: 10px;
  font-size: 40px;
  display: block;
  margin-top: 85px;
  line-height: 67px;
  height: 40px;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  text-align: center;
}

.Results {
  width: 100%;
  height: auto;
}

.result-placeholder {
  width: 100%;
  height: 50px;
  display: block;
  border: 1px dotted #efefef;
}

.result-placeholder:nth-child(even) {
  background: #efefef;
}

.result-placeholder .pest {
  box-sizing: border-box;
  padding: 0px 10px;
  width: 50%;
  height: 100%;
  font-size: 20px;
  line-height: 50px;
  display: inline-block;
}

.result-placeholder .status {
  box-sizing: border-box;
  padding: 0px 10px;
  width: 50%;
  height: 100%;
  font-size: 15px;
  line-height: 50px;
  float: right;
  text-align: right;
  text-transform: uppercase;
  font-style: italic;
  font-weight: bolder;
}
</style>
